// Bootstrap variable overrides
// Find list of variables at https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss
$primary: #1065d0;
$secondary: #435570;
$success: #17d1cb;
$info: #927dff;
$warning: #f9c80e;
$danger: #f65a4c;
$gray-100: #f5f6fa;
$gray-800: #002b49 !default;
$gray-900: #002b49 !default;
$black: #002b49 !default;

$border-radius: 0.15rem;
$border-radius-sm: 0.15rem;
$border-radius-lg: 0.15rem;

$form-check-input-border-radius: 0.15rem;

$headings-font-weight: 700;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Import bootstrap library
// Variables in here contain !default so will not override our custom variables above
@import "~bootstrap/scss/bootstrap.scss";

@media (max-width: 991px) { 
  .mg-t-5 {
    margin-top: 3rem;
  }
  .pd-l-5 {
    padding-left: 1.5rem;
  }
  .hor-divider {
    border-right: "none"
  }
}

@media (min-width: 992px) { 
  .mg-t-5 {
    margin-top: 0;
  }
  .pd-l-r {
    padding-left: 3rem;
  }
  .hor-divider {
    border-right: 1px solid #EAECF2
  }
}

.status-circle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-top: 4px;
  margin-left: 5px;
  display: inline-block;
}
