// Google Material Design Icons
@import "./material-icons";

// Set the height and flex of html, body and root for sticky bottom footer template
@import "./_layout";

// Bootstrap with our custom theme overrides
@import "./_custom-bootstrap";

// ⚠️ Add any other custom styles below but try to just use bootstrap as much as you can ⚠️
// Grid/Layout:       https://react-bootstrap.github.io/layout/grid/
// Media:             https://react-bootstrap.github.io/layout/media/
// Components:        https://react-bootstrap.github.io/components/alerts/
// Spacing:           https://getbootstrap.com/docs/4.0/utilities/spacing/
