// https://google.github.io/material-design-icons/#icon-font-for-the-web
// @import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import url("https://cdn.materialdesignicons.com/4.5.95/css/materialdesignicons.min.css");

/*
  Rules for adjusting the sizing, background and opacity of the icons, copy-pasted from documentation;
  I don't know why these classes aren't just included in the above Google font stylesheet but hey-ho
*/
.mdi-18 {
  font-size: 18px;
}
.mdi-24 {
  font-size: 24px;
}
.mdi-36 {
  font-size: 36px;
}
.mdi-48 {
  font-size: 48px;
}
.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}
.md-light {
  color: rgba(255, 255, 255, 1);
}
.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}
