* {
  font-variant: no-common-ligatures;
}

html,
body,
#root {
  min-height: 100vh;
}

#router {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: none;
}

select {
  appearance: none;
  box-sizing: border-box;
  padding-right: 30px !important;
  font: inherit;
  background: linear-gradient(45deg, transparent 50%, #647692 50%),
    linear-gradient(135deg, #647692 50%, transparent 50%),
    linear-gradient(to right, white, white);
  background-position: calc(100% - 15px) 50%, calc(100% - 10px) 50%, 100% 0;
  background-size: 5px 5px, 5px 5px, 31px 100%;
  background-repeat: no-repeat;
}
